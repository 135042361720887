@import url(../node_modules/react-texty/styles.css);
@import url(./seniors-first-commons/styles/_all.css);
@import url(../node_modules/rc-steps/assets/index.css);
.__talkjs_popup {
  right: 300px !important;
  bottom: 0px !important; }

.chat-container div {
  color: #fff !important; }

.circleBorder {
  border-radius: 50% !important; }

.tabs a:hover {
  background: transparent !important; }

.main-button {
  height: 48px !important;
  outline: none;
  border: none;
  border-radius: 9px; }

.full-content {
  background: linear-gradient(180deg, #9AD2FC 0%, #FFFFFF 100%);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100% !important;
  min-height: 100vh !important;
  min-width: 100vw; }
